export const apiEndPoints = {
  //auth
  login: "/auth/login",
  // Subjects
  getSubject: "/subjects/getSubjects",
  addSubject: "/subjects/addSubjects",
  deleteSubject: "/subjects/deleteSubjects",
  editSubject: "/subjects/editSubject",
  getSubjectById: "/subjects/getSubjectById",
  getallsubjectname: "/subjects/getallsubjectname",
  // Video
  getAllVideo: "/video/getAllVideo",
  addVideo: "/video/addVideo",
  editVideo: "/video/editVideo",
  deleteVideo: "/video/videoDelete",
  getvideobyid: "/video/getvideobyid",
  // Study Material
  getStudyMaterial: "/studyMaterial/getSubjectTopics",
  getallstudyMaterial: "/studyMaterial/getallstudyMaterial",
  getStudyMaterialbyid: "/studyMaterial/getStudyById",
  addStudyMaterial: "/studyMaterial/addStudyMaterial",
  deleteStudyMaterial: "/studyMaterial/deleteStudyMaterial",
  editStudyMaterial: "/studyMaterial/editstudyMaterial",
  // Current affair
  getCurrentAffairs: "/currentAffairs/getallCurrentAffairs",
  addCurrentAffairs: "/currentAffairs/addCurrentAffairs",
  deleteCurrentAffairs: "/currentAffairs/deleteCurrentAffairs",
  getCurrentAffairsById: "/currentAffairs/getCurrentAffairsById",
  editCurrentAffairs: "/currentAffairs/editCurrentAffairs",
  // Blogs
  getBlog: "/blogs/getBlogs",
  getBlogById: "/blogs/getBlogById",
  addBlog: "/blogs/addBlog",
  editBlog: "/blogs/editBlog",
  deleteBlog: "/blogs/deleteBlog",
  // Whats New
  getWhatsNew: "/whatsNew/getwhatsnew",
  getWhatsNewById: "/whatsNew/getwhatsnewbyid",
  addWhatsNew: "/whatsNew/addwhatsnew",
  editWhatsNew: "/whatsNew/editwhatsnew",
  deleteWhatsNew: "/whatsNew/deletewhatsnew",
  // Daily Vocab
  getDailyVocab: "/dalyVocab/getDalyVocab",
  getDailyVocabById: "/dalyVocab/getDalyVocabById",
  editDailyVocab: "/dalyVocab/editDalyVocab",
  addDailyVocab: "/dalyVocab/addDalyVocab",
  deleteDailyVocab: "/dalyVocab/deleteDalyVocab",
  // E Book
  getebooks: "/books/geteebooks",
  getebooksbyid: "/books/getebooksbyid",
  Addebooks: "/books/addebook",
  editebooks: "/books/editebook",
  deleteEBook: "/books/deleteeBook",
  // Jobs
  getJobs: "/job/getJob",
  getJobById: "/job/getJobById",
  addJob: "/job/addJob",
  editJob: "/job/editJob",
  deleteJob: "/job/deleteJob",
  // Rwview
  getReviews: "/review/getReviews",
  getreviewnyid: "/review/getreviewbyid",
  addReview: "/review/addReview",
  editReview: "/review/editReview",
  deleteReview: "/review/reviewDelete",
  // Question
  addQuestion: "/question/addQuestion",
  getQustions: "/question/getquestion",
  editQuestion: "/question/editQuestion",
  deleteQuestion: "/question/deleteQuestion",
  getgetQuestionsListbySubjectid: "/question/getQuestionsListbySubjectid",
  // Quiz
  getQuiz: "/quiz/getQuizs",
  addQuiz: "/quiz/addQuiz",
  editQuiz: "/quiz/updateQuiz",
  deleteQuiz: "/quiz/deleteQuiz",
  //user
  addUser: "/user/addUser",
  getUser: "/users/getAllUsers",
  editUser: "/users/editUser",
  deleteUser: "/users/deleteUser",
};
